export default function() {
    $('.btn-popup').magnificPopup({
        type: 'inline',
        showCloseBtn: false,
        closeOnBgClick: false,
        callbacks: {
            beforeOpen() {
                $('body').addClass('mfp-active')
            },
            beforeClose() {
                $('body').removeClass('mfp-active')
            },
        },
    })

    $('.btn-popup-closing').magnificPopup({
        type: 'inline',
        showCloseBtn: false,
        closeOnBgClick: false,
        callbacks: {
            beforeOpen() {
                $('body').addClass('mfp-active')
            },
            beforeClose() {
                $('body').removeClass('mfp-active')
            },
        },
    })

    if ($('#popup-rules').length) {
        $.magnificPopup.open({
            items: {
                src: '#popup-rules'
            },
            type: 'inline',
            showCloseBtn: false,
            closeOnBgClick: false
        });
    }
    $('.close-btn').on( "click", function() {
        $.magnificPopup.close();
    });

    document.addEventListener('turbolinks:before-cache', () => {
        $.magnificPopup.close()
    })
}

