import 'slick-carousel'

function initSliderMain() {
  const $sliderTop = $('.slider-top');
  if (!$sliderTop.length) {
    return
  }
  $sliderTop.slick({
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    fade: true,
    dots: true,
  });

  document.addEventListener('turbolinks:before-cache', () => $sliderTop.slick('unslick'))
}

function initSliders() {
  initSliderMain();
}

export default initSliders
