export default function() {
    const formRemote = document.querySelector("#form-remote");
    if (!formRemote) return;

    function validationForm() {
        const checkboxAll = Array.from(formRemote.querySelectorAll('input[type="checkbox"]'));
        const inputAll = Array.from(formRemote.querySelectorAll('input'));

        inputAll.forEach(item => item.addEventListener('input', () => {
            const inputChecked = checkboxAll.find(item => item.checked);
            const btnSubmit = formRemote.querySelector('[type="submit"]');

            if (inputChecked) {
                btnSubmit.removeAttribute("disabled");
            } else {
                btnSubmit.setAttribute("disabled", "true");
            }
        }));
    }

    validationForm();



    formRemote.addEventListener("ajax:success", (event) => {
        $.magnificPopup.close();
        $.magnificPopup.open({
            items: {
                src: '#popup-success',
            },
            type: 'inline',
            showCloseBtn: false,
            closeOnBgClick: false,
            callbacks: {
                beforeOpen() {
                    $('body').addClass('mfp-active')
                },
                beforeClose() {
                    $('body').removeClass('mfp-active')
                },
            },
        })
    });


    formRemote.addEventListener('ajax:error', (event) => {
        Turbolinks.dispatch('turbolinks:load')
        scrollTo(0, 0)
    });
}
