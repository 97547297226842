import './polyfills'
import FlashNotice from './flash_notice'
import PopupInit from './popupInit.js'
import FormInit from './form.js'
import SliderInit from './slider.js'
import SandwichInit from './sandwich.js'
import LocalTime from "local-time"
import SliderSlickInit from './sliderSlick.js'
import SelectInit from './select.js'
import YoutubeInit from './youtube.js'
import initVideoList from './video-list';


document.addEventListener('turbolinks:load', function() {
  new FlashNotice();
  PopupInit();
  FormInit();
  SliderInit();
  SandwichInit();
  LocalTime.start();
  SliderSlickInit();
  SelectInit();
  YoutubeInit();
  initVideoList();
})


window.update_recaptcha_token = (el_id,token) => {
  let hiddenInput = document.getElementById(el_id)
  hiddenInput.value = token
  hiddenInput.innerHTML = ''
}