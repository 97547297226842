require("@rails/ujs").start()
require("turbolinks").start()
require("magnific-popup/dist/jquery.magnific-popup.min.js")

var jQuery = require("jquery")
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
import './site/init'



