export default function () {
    const linkToYouTubeAll = document.querySelectorAll('.js-link-to-youtube');
    if (!linkToYouTubeAll.length) return;

    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    function onPlayerReady(event) {
        event.target.playVideo();
    }

    function onYouTubeIframeAPIReady(playerEl, youtubeId, linkEl) {
        new YT.Player(playerEl, {
            height: '200',
            width: '336',
            videoId: youtubeId,
            events: {
                'onReady': onPlayerReady,
            }
        });
        linkEl.style.display = 'none'
    }

    linkToYouTubeAll.forEach((linkNode) => {
        linkNode.addEventListener('click', (e) => {
            e.preventDefault();
            const youtubeId = e.currentTarget.dataset.youtubeId
            const playerEl = document.getElementById(youtubeId)
            onYouTubeIframeAPIReady(playerEl, youtubeId, e.currentTarget)
        })
    })
}
